import UserIcon from "assets/icons/User";
import { getToken } from "helpers";
import { useAxios } from "hooks/useAxios";
import { ChangeEvent, useEffect, useState } from "react";
import styled from "styled-components";
import Button from "components/Buttons/Button";
import { baseTheme } from "styles/theme";
import EditIcon from "assets/icons/EditIcon";

import {
  ButtonEdit,
  Column,
  StyledText,
  ColumnEdit,
  Title,
} from "components/InfoLog/styles";
import Modal from "components/Modal/Modal";
import Toast from "components/Toast";
import Spinner from "components/Spinners/ButtonSpinner";

interface User {
  [key: string]: string;
}
const StyledTextContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
const EditInput = styled.input`
  border: none;
  outline: none;
  font-family: "Poppins", sans-serif;
  padding: 4px;
  font-size: 0.9rem;
  border-bottom: 1px solid ${baseTheme.colors.main};
`;
export const TitleSection = styled.div`
  font-size: 1.4rem;
  line-height: 36px;
  font-weight: 700;
  color: ${baseTheme.colors.textTable};
  @media (max-width: 500px) {
    font-size: 1.1rem;
  }
`;
const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
const EditUsersStyles = styled.div`
  width: 100%;
`;
const SCButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
`;

interface IEditUserPage {
  isOpen: boolean;
  onRequestClose: () => void;
}
function EditUserModal({ isOpen, onRequestClose }: IEditUserPage) {
  const token = getToken();
  const userReviewLogId = localStorage.getItem("userReviewLogId");
  const { request, error, loading } = useAxios();

  const [userInfo, setUserInfo] = useState<User>();
  const [editState, setEditState] = useState({
    first_name: false,
    last_name: false,
    email: false,
    phone: false,
    type: false,
    speciality: false,
    roles: false,
    expired_at: false,
    subscribe_status: false,
    suspend_status: false,
  });

  const toggleEdit = (field: string) => {
    setEditState((prevState) => ({
      ...prevState,
      [field]: !prevState[field as keyof typeof prevState],
    }));
  };

  useEffect(() => {
    if (userReviewLogId !== null) {
      getData();
    }

    // eslint-disable-next-line
  }, [userReviewLogId]);
  console.log("userReviewLogId", userReviewLogId);
  const getData = async () => {
    const res = await request(`admin/user/${userReviewLogId}`, "GET", null, {
      Authorization: `Bearer ${token}`,
    });
    setUserInfo({
      first_name: res.data.first_name || "N/A",
      last_name: res.data.last_name || "N/A",
      email: res.data.email || "N/A",
      phone: res.data.phone || "N/A",
      type: res.data.type || "N/A",
      // speciality: res.data.speciality || "N/A",
    });
  };
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUserInfo((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const onSaveUserDetails = async (field: string) => {
    let updatedValue: string = userInfo![field];
    const updatedData: Partial<User> = {
      [field]: updatedValue,
    };
    await request(`admin/user/${userReviewLogId}`, "POST", updatedData, {
      Authorization: `Bearer ${token}`,
    });
    setEditState({
      first_name: false,
      last_name: false,
      email: false,
      phone: false,
      type: false,
      speciality: false,
      roles: false,
      expired_at: false,
      subscribe_status: false,
      suspend_status: false,
    });
    getData();
  };
  const renderField = (
    field: string,
    label: string,
    value: string,
    edit: boolean
  ) => {
    return edit ? (
      <ColumnEdit key={field}>
        <Title>{label}:</Title>
        <EditInput
          type="text"
          name={field}
          autoFocus
          required={true}
          value={userInfo![field]}
          onChange={handleInputChange}
        />
        <SCButtons>
          <Button
            fontWeight={500}
            fontSize="0.8rem"
            text="Save"
            as="button"
            bg="transparent"
            onClick={() => onSaveUserDetails(field)}
            color={baseTheme.colors.black}
          />
          <Button
            fontWeight={500}
            fontSize="0.8rem"
            text="Cancel"
            as="button"
            bg="transparent"
            onClick={() => toggleEdit(field)}
            color={baseTheme.colors.black}
          />
        </SCButtons>
      </ColumnEdit>
    ) : (
      <Column key={field}>
        <Title>{label}:</Title>
        <StyledTextContainer>
          <StyledText>{value || "N/A"}</StyledText>
          <ButtonEdit onClick={() => toggleEdit(field)}>
            <EditIcon
              width={20}
              height={20}
              color={baseTheme.colors.textGray}
            />
          </ButtonEdit>
        </StyledTextContainer>
      </Column>
    );
  };

  return (
    <>
      <Modal
        icon={<UserIcon width={36} height={36} color={baseTheme.colors.main} />}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        children={
          userInfo &&
          (loading ? (
            <Spinner />
          ) : (
            <Container>
              <EditUsersStyles>
                {renderField(
                  "first_name",
                  "First Name",
                  userInfo.first_name,
                  editState.first_name
                )}
                {renderField(
                  "last_name",
                  "Last name",
                  userInfo.last_name,
                  editState.last_name
                )}
                {renderField("email", "Email", userInfo.email, editState.email)}
                {renderField("phone", "Phone", userInfo.phone, editState.phone)}
                {/* {renderField("type", "Type", userInfo.type, editState.type)} */}
                {/* {renderField(
                  "speciality",
                  "Speciality",
                  userInfo.speciality,
                  editState.speciality
                )} */}
              </EditUsersStyles>
            </Container>
          ))
        }
        title={"Edit user details"}
      />
      {error && <Toast error={error} />}
    </>
  );
}

export default EditUserModal;
