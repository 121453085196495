import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { baseTheme } from "styles/theme";
import CalendarIcon from "assets/icons/CalendarIcon";

const DatePickerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${baseTheme.colors.gray};
  border-radius: 45px;
  transition-duration: 0.3s;
  border: 1px solid ${baseTheme.colors.gray};
`;

const StyledDatePicker = styled(DatePicker)`
  outline: none;
  background-color: transparent;
  font-family: "Poppins", sans-serif;
  border-color: transparent;
  line-height: 1;
  font-size: 12px;
  border: none;
  flex: 1;
  font-weight: 500;
`;
const PickerLabel = styled.div`
  font-family: "Poppins", sans-serif;
  line-height: 1;
  width: 100%;
  font-size: 12px;
  padding-right: 10px;
  pointer-events: auto;
  font-weight: 500;
  @media (min-width: 1921px) {
    font-size: 20px;
  }
`;

const DatePickerIcon = styled.div`
  display: flex;
  align-items: center;
  padding-right: 0.75rem;
`;

interface IDatePickerComponent {
  startDate: Date | null;
  endDate: Date | null;
  setStartDate: (value: Date) => void;
  setEndDate: (value: Date) => void;
}

const DatePickerComponent = ({
  endDate,
  startDate,
  setStartDate,
  setEndDate,
}: IDatePickerComponent) => {
  const handleStartDateChange = (date: Date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: Date) => {
    setEndDate(date);
  };

  return (
    <div style={{ position: "relative", zIndex: 2 }}>
      <DatePickerWrapper>
        <div
          style={{
            height: "45px",
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "40%",
              alignItems: "center",
              padding: "0.75rem",

              flexDirection: "row",
            }}
          >
            <PickerLabel>From:</PickerLabel>
            <StyledDatePicker
              selected={startDate}
              onChange={handleStartDateChange}
              selectsStart
              startDate={startDate}
              endDate={endDate}
            />
          </div>

          <DatePickerIcon>➔</DatePickerIcon>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0.75rem",
              flexDirection: "row",
              width: "40%",
              position: "relative",
            }}
          >
            <PickerLabel>To:</PickerLabel>
            <StyledDatePicker
              selected={endDate}
              onChange={handleEndDateChange}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
            />
          </div>
          <DatePickerIcon>
            <CalendarIcon width={22} height={22} />
          </DatePickerIcon>
        </div>
      </DatePickerWrapper>
    </div>
  );
};

export default DatePickerComponent;
