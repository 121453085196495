import PathologiesPending from "components/PathologiesPending";
import MainLayout from "layouts/MainLayout";

export default function PathologiesPendingPage() {
  return (
    <>
      <MainLayout children={<PathologiesPending />} />
    </>
  );
}
