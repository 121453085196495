import React, { useReducer } from "react";
import UserType from "components/Auth/UserType";
import { getToken, saveUserType } from "helpers";
import { useAxios } from "hooks/useAxios";
import InformModal from "./ConfirmModal";
import CheckOutlined from "@ant-design/icons/CheckOutlined";
import { baseTheme } from "styles/theme";
import userTypeButtons from "data/userTypeButtons";

interface IUserTypeModal {
  onRequestClose: () => void;
  settingsProfile?: () => void;
}

function UserTypeModal({ onRequestClose, settingsProfile }: IUserTypeModal) {
  const { request } = useAxios();
  const token = getToken();
  const [show, toggle] = useReducer((show) => !show, false);
  async function setTypeUser(userType: string) {
    const res = await request(
      "profile/type-speciality",
      "POST",
      {
        type: userType,
        speciality: "deez nuts",
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );
    if ((res.message = "Updated")) {
      toggle();
      if (settingsProfile) {
        settingsProfile();
      }

      saveUserType(userType);
      setTimeout(() => {
        toggle();
      }, 1000);
    }
  }

  return (
    <>
      <UserType buttons={userTypeButtons} onPress={setTypeUser} />
      <InformModal
        isOpen={show}
        title={"User type changed"}
        icon={
          <CheckOutlined
            style={{ fontSize: "24px", color: baseTheme.colors.main }}
          />
        }
      />
    </>
  );
}

export default UserTypeModal;
