import Modal from "components/Modal/Modal";
import UpdateModalInfo from "components/Modal/UpdateProfileModal";
import { SettingsArrayType } from "interfaces";
import { ReactNode } from "react";

interface IHospitals {
  title: string;
  isOpen: boolean;
  onRequestClose: () => void;
  type: string;
  settingsProfile?: () => void;
  label: string;
  titleList?: string;
  loading: boolean;
  data?: SettingsArrayType;
  icon?: ReactNode;
}

export default function Hospitals({
  isOpen,
  onRequestClose,
  type,
  settingsProfile,
  titleList,
  label,
  icon,
  loading,
  data,
  title,
}: IHospitals) {
  return (
    <Modal
      isOpen={isOpen}
      icon={icon}
      onRequestClose={onRequestClose}
      children={
        <UpdateModalInfo
          settingsProfile={settingsProfile!}
          type={type}
          label={label}
          titleList={titleList}
          isLoading={loading}
          data={data!}
          isHospitals
        />
      }
      title={title}
    ></Modal>
  );
}
