import PaperClip from "assets/icons/PaperClip";
import React, { ChangeEvent } from "react";
import styled from "styled-components";
import { baseTheme } from "styles/theme";
import Modal from "./Modal/Modal";
import ImagePreview from "./Modal/ImagePreview";

interface IButtonAdd {
  link: boolean;
}

const Label = styled.label``;

const ButtonAdd = styled.div<IButtonAdd>`
  display: flex;
  padding: 10px;
  color: ${(props) =>
    props.link ? baseTheme.colors.link : baseTheme.colors.white};
  width: 100%;
  text-decoration: ${(props) => (props.link ? "underline" : "none")};
  height: 45px;
  border-radius: 49px;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.link ? "transparent" : baseTheme.colors.main};
  cursor: pointer;
`;
const Input = styled.input`
  display: none;
`;

const Text = styled.span`
  font-size: 15px;
  font-weight: 600;
  padding-right: 5px;
  @media (min-width: 1921px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

interface FileInputProps {
  addPhotos: () => void;
  previewsModal: string[];
  setPreviewsModal: any;
  showPreview: boolean;
  setShowPreview: (value: boolean) => void;
  onChange: (selectedFiles: File[] | null, previews: string[]) => void;
  loading: boolean;
  title: string;
  isIcon: boolean;
  link: boolean;
}

const FileInput: React.FC<FileInputProps> = ({
  showPreview,
  setShowPreview,
  addPhotos,
  onChange,
  setPreviewsModal,
  previewsModal,
  loading,
  title,
  isIcon,
  link,
}) => {
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files || null;

    if (selectedFiles) {
      const newPreviews: string[] = [];

      Array.from(selectedFiles).forEach((file) => {
        const reader = new FileReader();

        reader.onloadend = () => {
          setPreviewsModal((previewsModal: any) => [
            ...previewsModal,
            reader.result as string,
          ]);
        };

        reader.readAsDataURL(file);
        newPreviews.push("");
      });

      onChange(Array.from(selectedFiles), newPreviews);
    } else {
      setPreviewsModal([]);
      onChange(null, []);
    }
    setShowPreview(!showPreview);
  };
  const CloseModal = () => {
    setShowPreview(false);
    setPreviewsModal([]);
  };
  return (
    <>
      <Label>
        <Input
          type="file"
          onChange={handleFileChange}
          accept=".png, .jpeg, .jpg"
          multiple
        />
        {!showPreview && (
          <ButtonAdd link={link}>
            <Text>{title}</Text>
            {isIcon && <PaperClip color={baseTheme.colors.white} />}
          </ButtonAdd>
        )}
      </Label>
      <Modal title="" isOpen={showPreview} onRequestClose={CloseModal}>
        <ImagePreview
          loading={loading}
          addPhotos={addPhotos}
          previews={previewsModal}
        />
      </Modal>
    </>
  );
};

export default FileInput;
