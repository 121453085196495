import PathologiesСhecked from "components/PathologiesChecked";
import MainLayout from "layouts/MainLayout";

export default function PathologiesCheckedPage() {
  return (
    <>
      <MainLayout
        children={
          <>
            <PathologiesСhecked />
          </>
        }
      />
    </>
  );
}
