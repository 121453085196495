// eslint-disable-next-line import/no-anonymous-default-export
const supervisionLevel = [
  { value: "independent", label: "Independent" },
  { value: "Supervised", label: "Supervised" },
  { value: "1st Assistant", label: "1st Assistant" },
  { value: "2nd Assistant", label: "2nd Assistant" },
  { value: "Observed", label: "Observed" },
];

export default supervisionLevel;
