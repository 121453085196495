import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { useNavigate } from "react-router-dom";
import { baseTheme } from "styles/theme";
import MenuOutlined from "@ant-design/icons/MenuOutlined";
import { getToken, getUserRole } from "helpers";
import logOut from "helpers";

import Logo from "assets/icons/Logo";

interface HamburgerButtonProps {
  onClick: () => void;
  isOpen: boolean;
}

interface HamburgerButtonProps {
  onClick: () => void;
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;
const ButtonLogout = styled.div`
  margin-bottom: 15px;
  display: flex;
  cursor: pointer;
`;
export const MenuItem = styled.a`
  display: flex;
  font-family: "Poppins", sans-serif;
  background-color: transparent;
  border: none;
  color: ${baseTheme.colors.black};
  cursor: pointer;
  padding: 5px 0;
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 10px;
  text-decoration: none;
  position: relative;

  &:hover::before {
    content: "";
    background-color: rgba(0, 128, 0, 0.1);
  }
`;

export const TextButton = styled.span`
  padding: 5px 20px;
  cursor: pointer;
  line-height: 1;
  font-size: 16px;
  font-weight: 600;
  list-style: none;
  &:hover {
    color: ${baseTheme.colors.main};
  }
`;
const LogoutText = styled.div`
  padding: 5px 20px;
  cursor: pointer;
  line-height: 1;
  font-size: 15px;
  font-weight: 600;
  color: ${baseTheme.colors.main};
  &:hover {
    color: ${baseTheme.colors.main};
  }
`;
export const StyledHamburgerMenu = styled.div`
  position: relative;
  display: flex;
  z-index: 1;
  background-color: ${baseTheme.colors.white};
  justify-content: space-between;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  padding: 9px 15px;
  max-width: 1140px;
  @media (min-width: 991px) {
    display: none;
  }
`;

export const StyledHamburgerButton = styled.div`
  position: relative;
  padding: 6px 9px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  cursor: pointer;
  border: 1px solid ${baseTheme.colors.black};
  z-index: 2;
  transition: all 0.3s ease-in-out;

  &:hover div {
    background-color: ${baseTheme.colors.main};
  }

  &.open {
    div:nth-child(1) {
      transform: rotate(-45deg);
      /* margin-top: 10px; */
    }
    div:nth-child(2) {
      opacity: 0;
      opacity: 0;
      transform: rotate(45deg);
    }
    div:nth-child(3) {
      transform: rotate(45deg);
      margin-top: -13px;
    }
  }
`;
const TitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
`;

export const StyledMenuItems = styled.ul`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${baseTheme.colors.gray};
  padding-top: 50px;
  z-index: 1;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 50%;
  animation: ${fadeIn} 0.3s ease-in-out;
`;

function HamburgerButton({ onClick, isOpen }: HamburgerButtonProps) {
  return (
    <StyledHamburgerButton onClick={onClick}>
      <MenuOutlined
        style={{ fontSize: "20px", color: baseTheme.colors.black }}
      />
    </StyledHamburgerButton>
  );
}
interface ILink {
  name: string;
  to: string;
}

interface IHeaderMenuMobile {
  links: ILink[];
  logout?: boolean;
  landing?: boolean;
}

function HeaderMenuMobile({ links, logout, landing }: IHeaderMenuMobile) {
  const statusRole = getUserRole();
  const token = getToken();
  const linkHome =
    statusRole === "admin" || statusRole === "superadmin"
      ? "/users"
      : "/case-logs";

  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const handleLinkClick = () => {
    setIsOpen(false);
  };
  function onLogOut() {
    logOut();
    navigate("/");
  }
  return (
    <StyledHamburgerMenu>
      <TitleWrap>
        <Logo
          color={baseTheme.colors.black}
          width={40}
          height={40}
          strokeWidth={4}
        />
      </TitleWrap>
      <HamburgerButton onClick={toggleMenu} isOpen={isOpen} />
      {isOpen && (
        <StyledMenuItems>
          {landing && (
            <>
              {token && (
                <MenuItem href={linkHome}>
                  <TextButton>My Account</TextButton>
                </MenuItem>
              )}
              {!token && (
                <>
                  <MenuItem href="/login">
                    <TextButton> Login</TextButton>
                  </MenuItem>
                  <MenuItem href="/register">
                    <TextButton>Sign up</TextButton>
                  </MenuItem>
                </>
              )}
            </>
          )}
          {links.map((item: any) => (
            <MenuItem href={item.to} onClick={handleLinkClick}>
              <TextButton>{item.name}</TextButton>
            </MenuItem>
          ))}
          {logout && (
            <ButtonLogout onClick={onLogOut}>
              <LogoutText>Logout</LogoutText>
            </ButtonLogout>
          )}
        </StyledMenuItems>
      )}
    </StyledHamburgerMenu>
  );
}
export default HeaderMenuMobile;
