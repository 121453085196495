import React, { MouseEvent, useState } from "react";
import styled from "styled-components";
import { useTable, useSortBy } from "react-table";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import { baseTheme } from "styles/theme";
import SkeletonTableComponent from "./Skeletons/SkeletonTable";
import ActivateUser from "assets/icons/ActivateUser";
import MakeAdmin from "assets/icons/MakeAdmin";
import RevokeAdmin from "assets/icons/RevokeAdmin";
import SuspendUser from "assets/icons/SuspendUser";
import TooltipComponent from "./ReactTooltip";
import ReviewUserslog from "assets/icons/ReviewUserslog";
import EditIcon from "assets/icons/EditIcon";
import { StyledBoldText } from "./Auth/styles";
import { getToken, getFromLocalStorageUser, IUserLSorage } from "helpers";
import { useAxios } from "hooks/useAxios";
import InformModal from "./Modal/ConfirmModal";
import { useNavigate } from "react-router-dom";
import EditUserModal from "pages/EditUsersPage";

interface UserType {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  roles: string;
  suspend_status: string | null;
  actions: {
    handleApprove: (user: UserType) => void;
    handleNotApprove: (user: UserType) => void;
  };
}
interface ColumnConfig {
  Header: string;
  accessor: keyof UserType;
}

interface ITableUsers {
  data: UserType[];
  columns: ColumnConfig[];
  onRowClick?: (rowData: UserType) => void;
  loading: boolean;
  actions?: boolean;
  meta: CurrentPageData;
  fetchData?: () => void;
  setUsersData: (value: []) => void;
  setMeta: (value: CurrentPageData) => void;
}
export interface CurrentPageData {
  current_page: number;
  from: number;
  last_page: number;
  links: {
    url: string;
    label: string;
    active: boolean;
  }[];
  path: string;
  per_page: number;
  to: number;
  total: number;
}

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;
const Icon = styled.div`
  padding: 0 3px;
  /* display: flex;
  align-items: center; */
`;
const StyledTable = styled.table`
  width: 100%;
  cursor: pointer;
  min-width: 760px;

  border-collapse: collapse;
  border: 1px solid ${baseTheme.colors.black};

  th,
  td {
    padding: 12px 6px;
    text-align: left;
    border: 1px solid ${baseTheme.colors.black};

    @media (max-width: 991px) {
      font-size: 11px;
    }
    @media (min-width: 1280px) {
      font-size: 14px;
    }
  }
  td {
    font-size: 14px;
  }
  th {
    color: ${baseTheme.colors.white};
    font-weight: 500;
    font-size: 12px;
    font-family: "Poppins", sans-serif;
    cursor: pointer;
    background-color: ${baseTheme.colors.black};
  }
`;
const StyledTableWrapper = styled.div`
  height: 100vh;
  overflow: auto;
  padding: 0 10px;
`;

const StyledTableHead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 0;
  background-color: white;
`;

const TableUsers: React.FC<ITableUsers> = ({
  data,
  columns,
  actions,
  fetchData,
  onRowClick,
  loading,
}) => {
  const navigate = useNavigate();
  const token = getToken();
  const user: IUserLSorage = getFromLocalStorageUser("user");
  const { email, role } = user || {};
  const { request } = useAxios();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");
  const [typeAction, setTypeAction] = useState("");
  const [curentUser, setCurrentUser] = useState<UserType>();
  const closeModal = () => {
    setShowModal(false);
  };
  const closeEditModal = () => {
    setShowEditModal(false);
  };
  const makeAdmin = (user: UserType) => {
    setShowModal(true);
    setTitle("Make admin?");
    setTypeAction("make");
    setCurrentUser(user);
  };
  const unMakeAdmin = (user: UserType) => {
    setShowModal(true);
    setTitle("Revoke admin?");
    setTypeAction("revoke");
    setCurrentUser(user);
  };
  const suspendUser = (user: UserType) => {
    setShowModal(true);
    setTitle("Suspend account?");
    setTypeAction("suspend");
    setCurrentUser(user);
  };
  const activatedUser = (user: UserType) => {
    setShowModal(true);
    setTitle("Activate account?");
    setTypeAction("activate");
    setCurrentUser(user);
  };

  const Confirm = () => {
    if (typeAction === "make") {
      handleApprove(curentUser!);
    }
    if (typeAction === "revoke") {
      handleNotApprove(curentUser!);
    }
    if (typeAction === "suspend") {
      handleSuspend(curentUser!);
    }
    if (typeAction === "activate") {
      handleActivate(curentUser!);
    }
  };
  const handleApprove = async (user: UserType) => {
    await request(`admin/user/${user.id}/add-admin-role`, "GET", null, {
      Authorization: `Bearer ${token}`,
    });
    if (fetchData) {
      fetchData();
    }
    closeModal();
  };

  const handleNotApprove = async (user: UserType) => {
    await request(`admin/user/${user.id}/remove-admin-role`, "GET", null, {
      Authorization: `Bearer ${token}`,
    });
    if (fetchData) {
      fetchData();
    }
    closeModal();
  };

  const handleSuspend = async (user: UserType) => {
    await request(`admin/user/${user.id}/suspend`, "GET", null, {
      Authorization: `Bearer ${token}`,
    });
    if (fetchData) {
      fetchData();
    }
    closeModal();
  };

  const handleActivate = async (user: UserType) => {
    await request(`admin/user/${user.id}/unsuspend`, "GET", null, {
      Authorization: `Bearer ${token}`,
    });
    if (fetchData) {
      fetchData();
    }
    closeModal();
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: data || [],
      },
      useSortBy
    );

  async function goToReviewUsersLogs(user: UserType) {
    localStorage.setItem("userReviewLogId", user.id.toString());
    navigate("/review-user-logs");
  }
  function goToEditUser(user: UserType) {
    setShowEditModal(true);
    localStorage.setItem("userReviewLogId", user.id.toString());
  }

  if (loading) {
    return (
      <SkeletonTableComponent
        numberOfColumns={columns.length}
        numberOfRows={22}
      />
    );
  }

  if (data.length === 0) {
    return <StyledBoldText>No users</StyledBoldText>;
  }

  return (
    <>
      <StyledTableWrapper>
        <StyledTable {...getTableProps()}>
          <StyledTableHead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                {headerGroup.headers.map((column: any, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                  >
                    {column.render("Header")}
                    <span
                      style={{
                        verticalAlign: "middle",
                        marginLeft: "5px",
                      }}
                    >
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FaSortDown />
                        ) : (
                          <FaSortUp />
                        )
                      ) : (
                        <FaSort />
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </StyledTableHead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              const user = row.original;
              const isCurrentUser = email === user.email;
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={row.id}
                  onClick={() => onRowClick && onRowClick(row.original)}
                >
                  {row.cells.map((cell, index) =>
                    actions && cell.column.id === "actions" ? (
                      <React.Fragment key={index}>
                        <td
                          onClick={(e: MouseEvent<HTMLTableCellElement>) => {
                            e.stopPropagation();
                            //open
                          }}
                        >
                          <IconsWrapper>
                            {user.roles === "user" && role === "superadmin" && (
                              <Icon
                                onClick={() => makeAdmin(user)}
                                data-tooltip-id="make_admin"
                              >
                                <MakeAdmin color={baseTheme.colors.main} />
                              </Icon>
                            )}
                            {user.roles === "admin" &&
                              role === "superadmin" && (
                                <Icon
                                  onClick={() => unMakeAdmin(user)}
                                  data-tooltip-id="revoke_admin"
                                >
                                  <RevokeAdmin color={baseTheme.colors.error} />
                                </Icon>
                              )}
                            {(role === "superadmin" || role === "admin") &&
                              user.suspend_status === "suspended" &&
                              !isCurrentUser && (
                                <Icon
                                  onClick={() => activatedUser(user)}
                                  data-tooltip-id="activate_account"
                                >
                                  <ActivateUser color={baseTheme.colors.main} />
                                </Icon>
                              )}
                            {(role === "superadmin" || role === "admin") &&
                              (user.suspend_status === null ||
                                user.suspend_status === "unsuspended") &&
                              !isCurrentUser && (
                                <Icon
                                  onClick={() => suspendUser(user)}
                                  data-tooltip-id="suspend_account"
                                >
                                  <SuspendUser color={baseTheme.colors.error} />
                                </Icon>
                              )}
                            {!isCurrentUser &&
                              user.roles !== "superadmin, admin" && (
                                <>
                                  <Icon
                                    onClick={() => goToReviewUsersLogs(user)}
                                    data-tooltip-id="review_users_case_log"
                                  >
                                    <ReviewUserslog
                                      color={baseTheme.colors.main}
                                    />
                                  </Icon>
                                  <Icon
                                    onClick={() => goToEditUser(user)}
                                    data-tooltip-id="edit_users_detail"
                                  >
                                    <EditIcon />
                                  </Icon>
                                </>
                              )}
                          </IconsWrapper>
                        </td>
                      </React.Fragment>
                    ) : (
                      <React.Fragment key={index}>
                        <td {...cell.getCellProps()}>
                          {cell.render("Cell") === null ||
                          cell.render("Cell") === "null"
                            ? "N/A"
                            : cell.render("Cell")}
                        </td>
                      </React.Fragment>
                    )
                  )}
                </tr>
              );
            })}
          </tbody>
        </StyledTable>
      </StyledTableWrapper>
      <TooltipComponent id={"make_admin"} content={"Make admin"} />
      <TooltipComponent id={"revoke_admin"} content={"Revoke admin"} />
      <TooltipComponent id={"activate_account"} content={"Activate account"} />
      <TooltipComponent id={"suspend_account"} content={"Suspend account"} />
      <TooltipComponent id={"edit_users_detail"} content={"Edit user detils"} />
      <TooltipComponent
        id={"review_users_case_log"}
        content={"Review Users Case log"}
      />
      <InformModal
        isOpen={showModal}
        cancelButton={closeModal}
        confirmButton={Confirm}
        title={title}
      />
      <EditUserModal onRequestClose={closeEditModal} isOpen={showEditModal} />
    </>
  );
};

export default TableUsers;
