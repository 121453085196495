import { ReactNode } from "react";
import { Card, IconWrapper, PaymentWrapper, Title } from "./style";
interface IPayment {
  text: string;
  icon: ReactNode;
  color: string;
}
function Payment({ text, icon, color }: IPayment) {
  return (
    <PaymentWrapper>
      <Card>
        <IconWrapper>{icon}</IconWrapper>
        <Title color={color}>{text}</Title>
      </Card>
    </PaymentWrapper>
  );
}

export default Payment;
