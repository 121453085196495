import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ChangeEvent, useState } from "react";
import useAuthStore from "store/authStore";
import { StyledH3 } from "styles/components";
import {
  StyleButtonWrapper,
  StyledButton,
  StyledDflex,
  StyledSpanText,
  Form,
  StyledRow,
  IconWrap,
} from "./styles";
import MailOutlined from "@ant-design/icons/MailOutlined";
import { ILoginForm } from "interfaces";
import Input from "components/Inputs/Input";
import Button from "components/Buttons/Button";
import { baseTheme } from "styles/theme";
import { useAxios } from "hooks/useAxios";
import Toast from "components/Toast";
import { logEvent } from "firebase/analytics";
import { analytics } from "App";
import { saveStatusMember, saveUserRole, saveUserType } from "helpers";
import EyeInvisibleOutlined from "@ant-design/icons/EyeInvisibleOutlined";
import EyeOutlined from "@ant-design/icons/EyeOutlined";
import InformModal from "components/Modal/ConfirmModal";
import AuthErrorStore from "store/AuthError";

function LoginForm() {
  const { setAuthData } = useAuthStore();
  const { authError, resetAuthError } = AuthErrorStore();
  const { request, error, loading, clearError } = useAxios();
  const navigate = useNavigate();
  const [token, setToken] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState<ILoginForm>({
    email: "",
    password: "",
  });
  const [suspendedError, setSuspendedError] = useState("");
  const [formDataErrors, setFormDataErrors] = useState<ILoginForm>({
    email: "",
    password: "",
  });
  function closeModal() {
    setShowModal(false);
  }
  async function Confirm() {
    navigate("/confirm-account");
    await request("auth/email/verification", "GET", null, {
      Authorization: `Bearer ${token}`,
    });
  }
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let error = "";

    if (name === "email") {
      if (!/\S+@\S+\.\S+/.test(value)) {
        error = "Invalid email address";
      }
      setFormDataErrors((prevFormDataErrors) => ({
        ...prevFormDataErrors,
        email: error,
      }));
    }

    if (name === "password") {
      if (value.length < 8) {
        error = "Password must be at least 8 characters long";
      }
      setFormDataErrors((prevFormDataErrors) => ({
        ...prevFormDataErrors,
        password: error,
      }));
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const loginDisabled = useMemo(() => {
    return (
      !formData.email ||
      !formData.password ||
      formDataErrors.email !== "" ||
      formDataErrors.password !== ""
    );
  }, [formData, formDataErrors]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    logEvent(analytics, "test_firebase_analitycs");
    clearError();
    resetAuthError();

    try {
      const res = await request("auth/login", "POST", {
        email: formData.email,
        password: formData.password,
      });
      setToken(res.access_token);
      if (res.suspend_status === "suspended") {
        setSuspendedError("Your account suspended");
        return;
      }
      if (res.email_verified_at === null) {
        setShowModal(true);
        return;
      }
      if (res.access_token) {
        saveUserRole(res.role);
        saveStatusMember(res.subscribe_status);
        saveUserType(res.type);

        try {
          setAuthData({
            accessToken: res.access_token,
            phone: res.phone,
          });
          await request("auth/2fa", "GET", null, {
            Authorization: `Bearer ${res.access_token}`,
          });

          navigate("/verification");
          setFormData({
            email: "",
            password: "",
          });
        } catch (e) {}
      }
      if (error) {
      }
    } catch (error) {
      // console.error("Error making POST request:", error);
    }
  };
  return (
    <>
      <StyledH3>Login</StyledH3>
      <Form onSubmit={handleSubmit}>
        <Input
          placeholder="Email"
          type="email"
          name="email"
          error={formDataErrors.email}
          autoComplete="email"
          required={true}
          value={formData.email}
          onChange={handleInputChange}
        />
        <Input
          name="password"
          type={showPassword ? "text" : "password"}
          autoComplete="current-password"
          required={true}
          error={formDataErrors.password}
          placeholder="Password"
          value={formData.password}
          onChange={handleInputChange}
          icon={
            <IconWrap onClick={togglePasswordVisibility}>
              {!showPassword ? (
                <EyeOutlined
                  style={{ color: baseTheme.colors.textGray, fontSize: "18px" }}
                />
              ) : (
                <EyeInvisibleOutlined
                  style={{ color: baseTheme.colors.textGray, fontSize: "18px" }}
                />
              )}
            </IconWrap>
          }
        />
        <StyledDflex>
          <StyledButton>
            <Button
              color={baseTheme.colors.main}
              mode="/resetpsw"
              justify="end"
              height="10px"
              text="Forgot Password?"
              as="a"
            />
          </StyledButton>
        </StyledDflex>
        <StyleButtonWrapper>
          <Button disabled={loginDisabled} loading={loading} text="Login" />
        </StyleButtonWrapper>
      </Form>
      <StyledRow>
        <StyledSpanText>Don't have an account?</StyledSpanText>
        <Button
          fontWeight={700}
          as="a"
          text="Sign up"
          color={baseTheme.colors.main}
          mode="/register"
        />
      </StyledRow>
      {suspendedError && <Toast error={suspendedError} />}
      {error && <Toast error={error} />}
      {authError && <Toast error={authError} />}
      <InformModal
        isOpen={showModal}
        icon={
          <MailOutlined
            style={{ fontSize: "36px", color: baseTheme.colors.error }}
          />
        }
        cancelButton={closeModal}
        textButton={"Verify My Account"}
        confirmButton={Confirm}
        title={"Your account is not verified"}
      />
    </>
  );
}

export default LoginForm;
