import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { baseTheme } from "styles/theme";
import { MdArrowBackIosNew } from "react-icons/md";
import Button from "./Button";

const ButtonWrapper = styled.div`
  position: absolute;
  top: 5px;
  display: flex;
  align-items: center;
  left: 10px;
  z-index: 1;
`;

export default function ButtonBack() {
  const navigate = useNavigate();
  return (
    <ButtonWrapper>
      <MdArrowBackIosNew color={baseTheme.colors.black} fontSize={18} />
      <Button
        fontWeight={500}
        fontSize="15px"
        flat={true}
        text="Back"
        as="a"
        color={baseTheme.colors.black}
        onClick={() => navigate(-1)}
      />
    </ButtonWrapper>
  );
}
