import styled from "styled-components";
import { LandingTitleSection } from "./About";
import { baseTheme } from "styles/theme";
import Button from "components/Buttons/Button";
import CheckOutlined from "@ant-design/icons/CheckOutlined";

const PricingWrap = styled.section`
  padding: 100px 0 20px;
`;
const PricingTitle = styled.h3`
  font-size: 50px;
  line-height: 58px;
  color: #263658;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
  @media (max-width: 991px) {
    font-size: 40px;
    line-height: 40px;
  }
  @media (max-width: 600px) {
    font-size: 32px;
  }
`;
const Text = styled.p`
  font-size: 16px;
  letter-spacing: 0;
  line-height: 25px;
  color: #484848;
  font-weight: 500;
  max-width: 380px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 30px;

  span {
    padding-right: 2px;
    color: ${baseTheme.colors.main};
  }
  @media (min-width: 1921px) {
    font-size: 20px;
  }
`;
const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: ${baseTheme.colors.white};
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  width: 450px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  filter: drop-shadow(0 15px 34px rgba(30, 47, 87, 0.1));
  @media (max-width: 991px) {
    width: 340px;
  }
`;
const CardHeader = styled.div`
  padding: 60px 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: ${baseTheme.colors.white};
  border: none;
  background-image: linear-gradient(to bottom right, #199a8e, black);

  h3 {
    font-size: 17px;
    letter-spacing: 3px;
    text-align: center;
    font-weight: 500;
    color: ${baseTheme.colors.white};
    @media (max-width: 991px) {
      font-size: 16px;
      letter-spacing: 6px;
    }
    @media (min-width: 1921px) {
      font-size: 21px;
    }
  }
  h1 {
    font-size: 55px;
    text-align: center;
    font-weight: 700;
    padding-top: 20px;
    color: ${baseTheme.colors.white};
    @media (max-width: 991px) {
      font-size: 40px;
    }
    @media (max-width: 600px) {
      font-size: 32px;
    }
  }
`;
const CardBody = styled.div`
  padding: 15px 25px;
  ul {
  }
  li {
    align-items: center;
    font-size: 22px;
    letter-spacing: 1px;
    color: #484848;
    font-weight: 400;
    border-bottom: 1px solid #e7e7e7;
    display: flex;
    padding: 15px;
    @media (max-width: 991px) {
      font-size: 18px;
      padding: 7px;
    }
  }
`;
const ButtonWrap = styled.div`
  padding: 20px;
`;
function Pricing() {
  return (
    <PricingWrap id="pricing">
      <LandingTitleSection>OUR PRICING</LandingTitleSection>
      <PricingTitle>Affordable simple pricing</PricingTitle>
      <Card>
        <CardHeader>
          <h3>OUR SUBSCRIPTION</h3>
          <h1>$9,99/Month</h1>
        </CardHeader>
        <CardBody>
          <ul>
            <li>
              <CheckOutlined
                style={{
                  paddingRight: "10px",
                  fontSize: "24px",
                  paddingBottom: "5px",
                  color: baseTheme.colors.main,
                }}
              />
              No hidden fees
            </li>
            <li>
              <CheckOutlined
                style={{
                  paddingRight: "10px",
                  fontSize: "24px",
                  paddingBottom: "5px",
                  color: baseTheme.colors.main,
                }}
              />
              Unlimited Case Logs
            </li>
            <li>
              <CheckOutlined
                style={{
                  paddingRight: "10px",
                  fontSize: "24px",
                  paddingBottom: "5px",
                  color: baseTheme.colors.main,
                }}
              />
              Customized reports
            </li>
            <li>
              <CheckOutlined
                style={{
                  paddingRight: "10px",
                  fontSize: "24px",
                  paddingBottom: "5px",
                  color: baseTheme.colors.main,
                }}
              />
              Free support
            </li>
          </ul>
        </CardBody>
        <ButtonWrap>
          <Button
            fontWeight={500}
            fontSize="14px"
            color={baseTheme.colors.white}
            bg={baseTheme.colors.main}
            as="a"
            text="Subscribe Today"
            mode={"/register"}
          />
        </ButtonWrap>
      </Card>
      <Text>
        <span>*</span> Start your Snap Log subscription and get access to all
        benefits today!
      </Text>
    </PricingWrap>
  );
}

export default Pricing;
