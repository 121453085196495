import React from "react";
import AuthForm from "components/Auth";
import AuthenticationLayout from "layouts/AuthLayout";

function ResetPassword() {
  return (
    <AuthenticationLayout>
      <AuthForm mode="resetpsw" />
    </AuthenticationLayout>
  );
}

export default ResetPassword;
