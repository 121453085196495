import styled from "styled-components";
import {
  ButtonEdit,
  Column,
  DFlex,
  StyledText,
  ColumnEdit,
  Title,
  TitleSectionContainer,
} from "./styles";
import { TitleSection } from "./InfoLog";
import EditIcon from "assets/icons/EditIcon";
import { baseTheme } from "styles/theme";
import { ICaseLog } from "interfaces";
import { convertDate, formatDate, getToken } from "helpers";
import { ChangeEvent, useState } from "react";
import { useAxios } from "hooks/useAxios";
import Button from "components/Buttons/Button";

interface PatientData {
  first_name: string;
  last_name: string;
  date_of_birth: string;
  ur_number: string;
  sex: string;
  [key: string]: string;
}

const EditInput = styled.input`
  border: none;
  outline: none;
  font-family: "Poppins", sans-serif;
  padding: 4px;
  font-size: 0.9rem;
  border-bottom: 1px solid ${baseTheme.colors.main};
  @media (min-width: 1921px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

const PatientDetailsStyles = styled.div``;

interface IPatientDetails {
  log: ICaseLog;
  getLog: () => void;
}

const SCButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
`;

const StyledTextContainer = styled.div`
  display: flex;
  width: 55%;
  justify-content: space-between;
`;

function PatientDetails({ log, getLog }: IPatientDetails) {
  const { request } = useAxios();
  const token = getToken();
  const dob = log?.patient && formatDate(log.patient.date_of_birth);
  const initialPatientData: PatientData = {
    first_name: log?.patient.first_name || "N/A",
    last_name: log?.patient.last_name || "N/A",
    date_of_birth: dob || "N/A",
    ur_number: log?.patient.ur_number || "N/A",
    sex: log?.patient.sex || "N/A",
  };

  const [patientData, setPatientData] =
    useState<PatientData>(initialPatientData);

  const [editState, setEditState] = useState({
    first_name: false,
    last_name: false,
    date_of_birth: false,
    ur_number: false,
    sex: false,
  });

  const toggleEdit = (field: string) => {
    setEditState((prevState) => ({
      ...prevState,
      [field]: !prevState[field as keyof typeof prevState],
    }));
  };

  const onSavePatDetails = async () => {
    await request(
      `patient/${log.patient.id}`,
      "POST",
      {
        first_name: patientData.first_name,
        last_name: patientData.last_name,
        date_of_birth: convertDate(patientData.date_of_birth),
        ur_number: patientData.ur_number,
        sex: patientData.sex,
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );
    setEditState({
      first_name: false,
      last_name: false,
      date_of_birth: false,
      ur_number: false,
      sex: false,
    });
    getLog();
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPatientData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const renderField = (
    field: string,
    label: string,
    value: string,
    edit: boolean
  ) => {
    return edit ? (
      <ColumnEdit key={field}>
        <Title>{label}:</Title>
        <EditInput
          type="text"
          name={field}
          required={true}
          value={patientData[field]}
          onChange={handleInputChange}
        />
        <SCButtons>
          <Button
            fontWeight={500}
            fontSize="0.8rem"
            text="Save"
            as="button"
            bg="transparent"
            onClick={onSavePatDetails}
            color={baseTheme.colors.black}
          />
          <Button
            fontWeight={500}
            fontSize="0.8rem"
            text="Cancel"
            as="button"
            bg="transparent"
            onClick={() => toggleEdit(field)}
            color={baseTheme.colors.black}
          />
        </SCButtons>
      </ColumnEdit>
    ) : (
      <Column key={field}>
        <Title>{label}:</Title>
        <StyledTextContainer>
          <StyledText>{value || "N/A"}</StyledText>
          <ButtonEdit onClick={() => toggleEdit(field)}>
            <EditIcon
              width={20}
              height={20}
              color={baseTheme.colors.textGray}
            />
          </ButtonEdit>
        </StyledTextContainer>
      </Column>
    );
  };

  return (

    <PatientDetailsStyles>
      <TitleSectionContainer>
        <DFlex>
          <TitleSection>Patient details</TitleSection>
        </DFlex>
      </TitleSectionContainer>
      {renderField(
        "first_name",
        "First Name",
        log?.patient.first_name,
        editState.first_name
      )}
      {renderField(
        "last_name",
        "Last Name",
        log?.patient.last_name,
        editState.last_name
      )}
      {renderField(
        "date_of_birth",
        "DOB",
        log?.patient.date_of_birth,
        editState.date_of_birth
      )}
      <Column>
        <Title>Age:</Title>
        <StyledText>{log?.patient.age || "N/A"}</StyledText>
      </Column>
      {renderField(
        "ur_number",
        "UR",
        log?.patient.ur_number,
        editState.ur_number
      )}
      {renderField("sex", "Sex", patientData.sex, editState.sex)}
    </PatientDetailsStyles>
  );
}

export default PatientDetails;
