import { IIcon } from "interfaces";

const ActivateUser = ({ color, width = 20, height = 20 }: IIcon) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path color={color} d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
        <path color={color} d="m9 12 2 2 4-4" />
      </svg>
    </div>
  );
};

export default ActivateUser;
