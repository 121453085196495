import styled from "styled-components";
import Select from "components/Select";
import Button from "components/Buttons/Button";
import Rotation from "data/rotation";
import { useAxios } from "hooks/useAxios";
import { ReactNode, useEffect, useMemo, useReducer, useState } from "react";
import { getToken } from "helpers";
import Modal from "components/Modal/Modal";
import { TitleSection } from "styles/components";
import { DefultSpan } from "./SetPgy";
import InformModal from "components/Modal/ConfirmModal";
import CheckOutlined from "@ant-design/icons/CheckOutlined";
import { baseTheme } from "styles/theme";

import Spinner from "components/Spinners/ButtonSpinner";
import { SettingsArrayType } from "interfaces";

export const WrapSpinner = styled.div`
  display: flex;
  align-items: center;
  padding-top: 40px;
  justify-content: center;
`;
interface IRotation {
  title: string;
  isOpen: boolean;
  onRequestClose: () => void;
  icon: ReactNode;
  data: SettingsArrayType;
  settingsProfile?: () => void;
}

interface IRotationDropdown {
  title?: string;
  options: any;
  outcomeLoading?: boolean;
  id?: string;
  data?: SettingsArrayType;
  toggle: () => void;
  settingsProfile?: () => void;
}

const OutcomesContent = styled.div`
  padding: 10px;
  width: 350px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  max-height: 450px;
  @media (min-width: 1921px) {
    width: 450px;
  }
`;

const ButtonWrapper = styled.div`
  padding-top: 5px;
  padding-bottom: 20px;
  width: 100%;
`;

function RotationDropdown({ options, toggle }: IRotationDropdown) {
  const token = getToken();
  const { request } = useAxios();
  const [defaultRotation, setRotationDefault] = useState();
  const [item, setItem] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    settingsProfile();
    // eslint-disable-next-line
  }, []);

  const buttonDisabled = useMemo(() => {
    return !item;
  }, [item]);

  async function settingsProfile() {
    setLoading(true);
    const res = await request("profile/operation-case/settings", "GET", null, {
      Authorization: `Bearer ${token}`,
    });

    setRotationDefault(res.data?.[0]?.presets.rotation);
    setLoading(false);
  }

  async function handleSubmit() {
    const res = await request(
      "profile/presets",
      "POST",
      {
        presets: {
          rotation: item,
        },
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if ((res.message = "Updated")) {
      toggle();
      settingsProfile();
      setTimeout(() => {
        toggle();
      }, 1000);
    }
  }
  if (loading) {
    return (
      <WrapSpinner>
        <Spinner size={40} />
      </WrapSpinner>
    );
  }
  return (
    <>
      <>
        <TitleSection>
          <>
            Your current default rotation is{" "}
            <DefultSpan>{defaultRotation}</DefultSpan>. If you want to change,
            choose from the list.
          </>
        </TitleSection>
        <OutcomesContent>
          <Select
            options={options}
            onChange={(value: string | number) => setItem(value.toString())}
          />
          <ButtonWrapper>
            <Button
              disabled={buttonDisabled}
              onClick={handleSubmit}
              text="Save"
            />
          </ButtonWrapper>
        </OutcomesContent>
      </>
    </>
  );
}

function Rotations({
  title,
  isOpen,
  onRequestClose,
  icon,
  data,
  settingsProfile,
}: IRotation) {
  const [show, toggle] = useReducer((show) => !show, false);
  return (
    <>
      <Modal
        title={title}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        icon={icon}
        children={
          <RotationDropdown
            data={data}
            options={Rotation}
            toggle={toggle}
            settingsProfile={settingsProfile}
          />
        }
      ></Modal>
      <InformModal
        isOpen={show}
        title={"Rotation changed"}
        icon={
          <CheckOutlined
            style={{ fontSize: "24px", color: baseTheme.colors.main }}
          />
        }
      />
    </>
  );
}

export default Rotations;
