import React, { MouseEvent } from "react";
import styled from "styled-components";
import { useTable, useSortBy } from "react-table";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import { baseTheme } from "styles/theme";
import ToggleSwitch from "./ToogleSwitcher";
import { OperationCase } from "interfaces";
import SkeletonTableComponent from "./Skeletons/SkeletonTable";

interface DataType {
  path_check: boolean;
}

interface ITable {
  columns: any;
  data: DataType[];
  loading: boolean;
  onRowClick?: (rowData: OperationCase) => void;
  activeTab?: string;
  openCheckModal?: (rowData: OperationCase) => void;
}

const StyledBoldText = styled.div`
  height: 100vh;
  padding-left: 30px;
  font-size: 1.1rem;
  line-height: 1.7;
  font-weight: 500;
  text-align: left;
`;

const StyledTable = styled.table`
  width: 100%;
  min-width: 761px;
  border-collapse: collapse;
  border: 1px solid ${baseTheme.colors.black};

  th,
  td {
    padding: 12px;
    text-align: left;
    font-weight: 500;
    font-size: 12px;
    /* line-height: 1.5; */
    font-family: "Poppins", sans-serif;
    border: 1px solid ${baseTheme.colors.black};
    @media (max-width: 991px) {
      font-size: 11px;
    }
    @media (min-width: 1280px) {
      font-size: 14px;
    }
    @media (min-width: 1921px) {
      font-size: 20px;
    }
  }

  th {
    color: ${baseTheme.colors.white};
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    cursor: pointer;
    background-color: ${baseTheme.colors.black};
    position: sticky;
    top: 0;
    z-index: 1;
  }

  tbody tr:hover {
    background-color: rgba(0, 128, 0, 0.1);
  }
`;

const ScrollableContainer = styled.div`
  overflow: auto;
  height: 100vh;
`;

const Table: React.FC<ITable> = ({
  columns,
  activeTab,
  data,
  loading,
  onRowClick,
  openCheckModal,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy
    );

  if (loading) {
    return (
      <SkeletonTableComponent
        numberOfColumns={columns.length}
        numberOfRows={data.length}
      />
    );
  }
  if (data.length === 0) {
    return <StyledBoldText>No logs</StyledBoldText>;
  }

  return (
    <ScrollableContainer>
      <StyledTable {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any, index) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={index}
                  style={{ verticalAlign: "middle" }}
                >
                  {column.Header === "Path check" &&
                  activeTab !== "Pathologies" ? null : (
                    <>
                      {column.render("Header")}
                      {!(
                        column.Header === "Path check" ||
                        column.Header === "UR number"
                      ) && (
                        <span
                          style={{
                            verticalAlign: "middle",
                            marginLeft: "5px",
                          }}
                        >
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <FaSortDown />
                            ) : (
                              <FaSortUp />
                            )
                          ) : (
                            <FaSort />
                          )}
                        </span>
                      )}
                    </>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                key={row.id}
                onClick={() =>
                  onRowClick && onRowClick(row.original as OperationCase)
                }
              >
                {row.cells.map((cell, index) =>
                  cell.column.id === "path_check" &&
                  activeTab === "Pathologies" ? (
                    <React.Fragment key={index}>
                      <td
                        onClick={(e: MouseEvent<HTMLTableCellElement>) => {
                          e.stopPropagation();
                          openCheckModal &&
                            openCheckModal(row.original as OperationCase);
                        }}
                      >
                        <ToggleSwitch
                          isChecked={cell.row.original.path_check}
                          onChange={() => {}}
                        />
                      </td>
                    </React.Fragment>
                  ) : (
                    <React.Fragment key={index}>
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    </React.Fragment>
                  )
                )}
              </tr>
            );
          })}
        </tbody>
      </StyledTable>
    </ScrollableContainer>
  );
};

export default Table;
