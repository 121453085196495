import React from "react";
import styled from "styled-components";
import { baseTheme } from "styles/theme";

const AboutWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 100px 0 20px;
`;
export const LandingTitleSection = styled.h3`
  color: ${baseTheme.colors.main};
  font-size: 18px;
  letter-spacing: 9px;
  line-height: 40px;
  font-weight: 600;
  padding-left: 20px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  @media (max-width: 991px) {
    font-size: 16px;
    letter-spacing: 6px;
  }
  @media (min-width: 1921px) {
    font-size: 25px;
  }
`;
const AboutColumn = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Card = styled.div`
  max-width: 450px;
  min-width: 300px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 20px;
  filter: drop-shadow(0 15px 34px rgba(30, 47, 87, 0.1));
  transition: box-shadow 0.25s;
`;
const Image = styled.img`
  width: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
`;
const TextWrapper = styled.div`
  background-color: ${baseTheme.colors.white};
  padding: 20px;
  flex-direction: column;
  flex: 1 1 auto;
  display: flex;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  filter: drop-shadow(0 5px 6.5px rgba(30, 47, 87, 0.09));
`;
const Title = styled.h3`
  font-size: 18px;
  letter-spacing: 9px;
  line-height: 61px;
  color: ${baseTheme.colors.main};
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  @media (min-width: 1921px) {
    font-size: 25px;
  }
`;
const Text = styled.p`
  letter-spacing: 0;
  color: #484848;
  font-weight: 400;
  text-align: center;
  font-size: 16px;
  line-height: 26px;
  @media (min-width: 1921px) {
    font-size: 20px;
    line-height: 31px;
  }
  @media (min-width: 2500px) {
    font-size: 24px;
    line-height: 40px;
  }
`;
function About() {
  return (
    <AboutWrapper id="about">
      <LandingTitleSection>ABOUT US</LandingTitleSection>
      <AboutColumn>
        <Card>
          <Image src="https://img1.wsimg.com/isteam/stock/a28D7m/:/cr=t:12.4%25,l:0%25,w:100%25,h:75.21%25/rs=w:600,h:300,cg:true" />
          <TextWrapper>
            <Title>Our history</Title>
            <Text>
              The idea for Snap Log was borne out of a conversation about
              surgical logbooks over dinner in late 2022.
            </Text>
            <br />
            <Text>
              Frustrated with the use archaic of stickers in notebooks and with
              the tedious data entry required for using speadsheet logbooks, the
              two co-founders envisioned a product that felt good to use and was
              quick enough to use in an operating theatre bewteen cases.{" "}
            </Text>
            <br />
            <Text>
              As result of their strong passion for to improve the lives of
              clinicians and their patients, Snaplog was founded in 2023 and
              made available for download in 2024.
            </Text>
          </TextWrapper>
        </Card>

        <Card>
          <Image src="https://img1.wsimg.com/isteam/stock/1YNpAdQ/:/cr=t:12.5%25,l:0%25,w:100%25,h:75%25/rs=w:600,h:300,cg:true" />
          <TextWrapper>
            <Title>Our mission</Title>
            <Text>
              We beleive that good clinical data should be captured in real time
              in the course of delivery of patient care. Not retrospectively by
              tediously reviewing clinical files and manually entering data into
              databases.
            </Text>
            <br />
            <Text>
              The vision for Snap Log is that barriers to data entry must be
              eliminated by prioritising a smooth user experience.
            </Text>
            <br />
            <Text>
              As a result, the Snap Log surgical logbook does not require any
              manual data entry. All fields can be populated using optical
              character recognition (OCR) technology.
            </Text>
          </TextWrapper>
        </Card>
      </AboutColumn>
    </AboutWrapper>
  );
}

export default About;
