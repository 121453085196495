import {
  IUserLSorage,
  getFromLocalStorageUser,
  getStatusMember,
  getToken,
} from "helpers";
import styled from "styled-components";
import { baseTheme } from "styles/theme";
import { useAxios } from "hooks/useAxios";
import { useReducer, useState } from "react";
import { StyledBoldText } from "components/Auth/styles";
import InformModal from "components/Modal/ConfirmModal";
import CheckOutlined from "@ant-design/icons/CheckOutlined";
import MobileOutlined from "@ant-design/icons/MobileOutlined";
import MailOutlined from "@ant-design/icons/MailOutlined";
import User from "assets/icons/User";

const ProfileUserinnerContainer = styled.div`
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  max-width: 400px;
  padding: 20px;
  margin: 0 auto;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  @media (min-width: 1921px) {
    max-width: 600px;
  }
`;
const ProfileUserouterContainer = styled.div`
  width: 50%;
  height: 100%;
  padding-top: 80px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-right: 2px solid ${baseTheme.colors.gray};
  @media (max-width: 900px) {
    border-right: none;
    padding-bottom: 50px;
  }
`;

const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(242, 242, 242);
  width: 130px;
  height: 130px;
  border-radius: 150px;
  margin-bottom: 20px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media (min-width: 1921px) {
    max-width: 600px;
  }
`;
const Letters = styled.p`
  font-size: 50px;
  color: ${baseTheme.colors.main};
  font-family: "Poppins", sans-serif;
`;
export const UserInfoRow = styled.div`
  flex-direction: "row";
  display: flex;
  padding-left: 20px;
  align-items: center;
  margin-bottom: 15px;
`;

export const Text = styled.p`
  font-family: "Poppins", sans-serif;
  color: #222222;
  text-align: left;
  font-size: 16px;
  line-height: 1.7;
  font-weight: 500;
  width: 350px;
  word-wrap: break-word;
  @media (min-width: 1921px) {
    font-size: 20px;
  }
`;
const TextSubscribe = styled.p`
  font-family: "Poppins", sans-serif;
  color: #222222;
  text-align: left;
  font-size: 15px;
  line-height: 1.7;
  flex: 1;
  font-weight: 500;
  @media (min-width: 1921px) {
    font-size: 20px;
  }
`;
export const IconBuble = styled.div`
  background-color: rgb(242, 242, 242);
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50px;
  margin-right: 10px;
`;
export const ButtonSubscribe = styled.button`
  border-radius: 8px;
  padding: 0;
  /* line-height: 1.4; */
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  justify-content: center;
  color: blue;
  padding: 0;
  font-weight: 600;
  font-size: 16px;
  border: none;
  text-decoration: underline;
  background-color: transparent;
  @media (min-width: 1921px) {
    font-size: 20px;
  }
  @media (min-width: 1921px) {
    font-size: 20px;
  }
`;

function ProfileUserinfo() {
  const statusMember = getStatusMember();
  const token = getToken();
  const [showSubscribe, toggleSubscribe] = useReducer(
    (showHospital) => !showHospital,
    false
  );

  const { request } = useAxios();
  const [subscriptionLink, setSubscriptionLink] = useState("");
  const user: IUserLSorage = getFromLocalStorageUser("user");
  const { first_name, role, last_name, email, phone, subscribe_status, type } =
    user;
  async function subscribe() {
    const res = await request("stripe/generate-payment-link", "GET", null, {
      Authorization: `Bearer ${token}`,
    });
    toggleSubscribe();
    setSubscriptionLink(res.url);
  }

  async function unSubscribe() {
    toggleSubscribe();
    await request("stripe/cancel-subscription-at-period-end", "GET", null, {
      Authorization: `Bearer ${token}`,
    });
  }

  const handlePayment = () => {
    if (statusMember === "subscribed") {
      toggleSubscribe();
    }
    if (
      statusMember === "demo" ||
      statusMember === "demo-expired" ||
      statusMember === "subscribe-expired"
    ) {
      subscribe();
    }
  };
  async function confirmHandlePayment() {
    if (statusMember === "subscribed") {
      unSubscribe();
    }
    if (statusMember === "demo" || "demo-expired" || "subscribe-expired") {
      window.location.href = subscriptionLink;
      toggleSubscribe();
    }
  }

  let titleSubscribe = "";
  switch (subscribe_status) {
    case "demo":
      titleSubscribe = "Would you like to subscribe?";
      break;
    case "demo-expired":
      titleSubscribe =
        "Your free trial has expired. Would you like to subscribe?";
      break;
    case "subscribe-expired":
      titleSubscribe =
        "Your subscription has expired. Would you like to subscribe?";
      break;
    case "subscribed":
      titleSubscribe = "Would you like to unsubscribe?";
      break;
  }

  function translateData(data: string) {
    switch (data) {
      case "set-trainee":
        return "SET trainee";
      case "consultant":
        return "Consultant";
      case "unaccredited-trainee":
        return "Unaccredited trainee";
    }
  }
  return (
    <>
      {first_name && last_name && (
        <>
          <ProfileUserouterContainer>
            <ProfileUserinnerContainer>
              <Avatar>
                <Letters>{first_name.slice(0, 1) || null}</Letters>
                <Letters>{last_name.slice(0, 1) || null}</Letters>
              </Avatar>
              <Content>
                <UserInfoRow>
                  <IconBuble>
                    <User
                      width={20}
                      height={20}
                      color={baseTheme.colors.main}
                    />
                  </IconBuble>
                  <Text>
                    {first_name} {last_name}
                  </Text>
                </UserInfoRow>
                <UserInfoRow>
                  <IconBuble>
                    <MailOutlined
                      style={{ fontSize: "20px", color: baseTheme.colors.main }}
                    />
                  </IconBuble>
                  <Text>{email}</Text>
                </UserInfoRow>
                <UserInfoRow>
                  <IconBuble>
                    <MobileOutlined
                      style={{ fontSize: "20px", color: baseTheme.colors.main }}
                    />
                  </IconBuble>
                  <Text>{phone}</Text>
                </UserInfoRow>
                {role !== "superadmin" && (
                  <>
                    <UserInfoRow>
                      <IconBuble>
                        <CheckOutlined
                          style={{
                            fontSize: "20px",
                            color: baseTheme.colors.main,
                          }}
                        />
                      </IconBuble>
                      <Text>{translateData(type)}</Text>
                    </UserInfoRow>
                    <StyledBoldText>
                      <TextSubscribe>
                        <span style={{ paddingRight: "5px" }}>
                          Your status:{" "}
                          <span style={{ fontWeight: "600" }}>
                            {subscribe_status}
                          </span>
                          . {titleSubscribe}
                        </span>
                        <ButtonSubscribe onClick={handlePayment}>
                          Click here
                        </ButtonSubscribe>
                      </TextSubscribe>
                    </StyledBoldText>
                  </>
                )}
              </Content>
            </ProfileUserinnerContainer>
          </ProfileUserouterContainer>

          <InformModal
            isOpen={showSubscribe}
            cancelButton={toggleSubscribe}
            confirmButton={confirmHandlePayment}
            title={titleSubscribe}
          />
        </>
      )}
    </>
  );
}

export default ProfileUserinfo;
