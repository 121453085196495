import DataComponent from "./DataComponent";

function PathologiesPending() {
  return (
    <DataComponent
      uri={"operation-case/pathology/unchecked?search="}
      title={"Pending Pathology"}
      pathButtonTitle="Checked Pathology"
      pathButtonMode="/pathologies-checked"
      activeTab={"Pathologies"}
    />
  );
}

export default PathologiesPending;
