import React from "react";
import { baseTheme } from "styles/theme";
import User from "assets/icons/User";
import { useLocation } from "react-router-dom";
import Users from "assets/icons/Users";
import { MenuContainer, MenuItem, TextButton } from "./styles";
import PrivacyPolicyIcon from "assets/icons/PrivacyPolicy";
import Reports from "assets/icons/Reports";
import TermsAndConditionsIcon from "assets/icons/TermsAndCondition";

const activePaths: {
  [key: string]: boolean;
} = {
  "/users-managment": true,
  "/review-user-logs": true,
  "/notes": true,
  "/review": true,
};

function SuperAdminTabs() {
  const location = useLocation();
  const isActive = activePaths[location.pathname];
  return (
    <MenuContainer>
      <MenuItem
        className={location.pathname === "/users" ? "active" : ""}
        to="/users"
      >
        <Users
          color={
            location.pathname === "/users"
              ? baseTheme.colors.main
              : baseTheme.colors.textGray
          }
        />
        <TextButton>General User Report</TextButton>
      </MenuItem>
      <MenuItem
        className={location.pathname === "/reports" ? "active" : ""}
        to="/reports"
      >
        <Reports
          color={
            location.pathname === "/reports"
              ? baseTheme.colors.main
              : baseTheme.colors.textGray
          }
        />
        <TextButton>Subscription report</TextButton>
      </MenuItem>
      <MenuItem className={isActive ? "active" : ""} to="/users-managment">
        <User
          color={isActive ? baseTheme.colors.main : baseTheme.colors.textGray}
        />
        <TextButton>Users Management</TextButton>
      </MenuItem>
      <MenuItem to="/edit-privacy-policy">
        <PrivacyPolicyIcon color={baseTheme.colors.textGray} />
        <TextButton>Edit Privacy Policy</TextButton>
      </MenuItem>
      <MenuItem to="/edit-terms-condition">
        <TermsAndConditionsIcon color={baseTheme.colors.textGray} />
        <TextButton>Edit Terms and Conditions</TextButton>
      </MenuItem>
      <MenuItem
        className={location.pathname === "/profile" ? "active" : ""}
        to="/profile"
      >
        <User
          color={
            location.pathname === "/profile"
              ? baseTheme.colors.main
              : baseTheme.colors.textGray
          }
        />
        <TextButton>Profile</TextButton>
      </MenuItem>
    </MenuContainer>
  );
}

export default SuperAdminTabs;
