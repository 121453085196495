import styled from "styled-components";

const LogoWrapper = styled.div`
  /* width: 24px;
  height: 24px; */
`;
interface ILogo {
  width?: number;
  height?: number;
  color?: string;
  strokeWidth?: number;
}
function Logo({
  strokeWidth = 4,
  color = "#199A8E",
  width = 47,
  height = 40,
}: ILogo) {
  return (
    <LogoWrapper>
      <svg
        width={width}
        height={height}
        viewBox="0 0 55 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.08325 45.2083C5.73507 45.2083 4.44211 44.6728 3.4888 43.7195C2.53549 42.7661 1.99992 41.4732 1.99992 40.125V14.7083C1.99992 13.3601 2.53549 12.0672 3.4888 11.1139C4.44211 10.1606 5.73507 9.625 7.08325 9.625H27.1624C28.0126 9.63333 28.8512 9.4283 29.6017 9.02867C30.3521 8.62904 30.9903 8.04758 31.4578 7.3375L33.5166 4.2875C33.9794 3.58465 34.6096 3.00772 35.3504 2.60847C36.0912 2.20921 36.9196 2.00014 37.7612 2H47.7499C49.0981 2 50.3911 2.53556 51.3444 3.48887C52.2977 4.44218 52.8333 5.73515 52.8333 7.08333V40.125C52.8333 41.4732 52.2977 42.7661 51.3444 43.7195C50.3911 44.6728 49.0981 45.2083 47.7499 45.2083H7.08325Z"
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.625 20V35.25"
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M35.25 27.625H20"
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </svg>
    </LogoWrapper>
  );
}

export default Logo;
