import { create } from "zustand";

interface AuthError {
  authError: string;
  setAuthError: (authError: string) => void;
  resetAuthError: () => void;
}

const AuthErrorStore = create<AuthError>((set) => ({
  authError: "",
  setAuthError: (authError) => set({ authError }),
  resetAuthError: () => set({ authError: "" }),
}));

export default AuthErrorStore;
