import React from "react";
import styled from "styled-components";
import Sidebar from "components/SideBar/SideBar";
import { getUserRole } from "helpers";
import HeaderMenuMobile from "pages/landing/Header/HeaderMenuMobile";
interface IMainLayout {
  children: React.ReactNode;
}

const MainLayoutWrapper = styled.div`
  @media (min-width: 991px) {
    display: flex;
  }
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  flex: 1;
  padding: 10px;
  background-color: #f2f2f2;
  overflow-y: auto;
`;
const menuLinksUser: ILink[] = [
  {
    name: "Case Logs",
    to: "/case-logs",
  },
  {
    name: "Complications",
    to: "/complications",
  },
  {
    name: "Pathology",
    to: "/pathologies-pending",
  },
  {
    name: "Reports",
    to: "/reports",
  },
  {
    name: "Profile",
    to: "/profile",
  },
];

const menuLinksAdmin: ILink[] = [
  {
    name: "General User Report",
    to: "/users",
  },
  {
    name: "Subscription report",
    to: "/reports",
  },
  {
    name: "Users Management",
    to: "/users-managment",
  },
  {
    name: "Profile",
    to: "/profile",
  },
];

const menuLinksSuperAdmin: ILink[] = [
  {
    name: "General User Report",
    to: "/users",
  },
  {
    name: "Subscription report",
    to: "/reports",
  },
  {
    name: "Users Management",
    to: "/users-managment",
  },
  {
    name: "Edit Privacy Policy",
    to: "/edit-privacy-policy",
  },
  {
    name: "Edit Terms and Conditions",
    to: "/edit-terms-condition",
  },
  {
    name: "Profile",
    to: "/profile",
  },
];
interface ILink {
  name: string;
  to: string;
}
export default function MainLayout({ children }: IMainLayout) {
  let menuLinks: ILink[] = [];
  const userRole = getUserRole();
  switch (userRole) {
    case "superadmin":
      menuLinks = menuLinksSuperAdmin;
      break;
    case "admin":
      menuLinks = menuLinksAdmin;
      break;
    case "user":
      menuLinks = menuLinksUser;
      break;
  }
  return (
    <MainLayoutWrapper>
      <Sidebar />
      <HeaderMenuMobile logout links={menuLinks} />
      <ContentContainer>{children}</ContentContainer>
    </MainLayoutWrapper>
  );
}
