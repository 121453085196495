import React, { useEffect, useState } from "react";
import TableUsers, { CurrentPageData } from "components/UserTable";
import { getToken, saveToLocalStorageUser } from "helpers";
import { useAxios } from "hooks/useAxios";
import { styled } from "styled-components";

import { Column, TitleTab, ContentContainer } from "components/DataComponent";
import Search from "components/Search";
import useDebounce from "hooks/useDebounce";
import Pagination from "./Pagination";

const Wrapper = styled.div`
  position: relative;
  background-color: rgb(242, 242, 242);
`;

const SearchWrap = styled.div`
  width: 50%;
  padding: 20px 10px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
interface IUsers {
  title?: string;
  actions?: boolean;
  columns?: any;
}

export default function Users({ title, actions, columns }: IUsers) {
  const [localSearchWord, setLocalSearchWord] = useState<string>("");
  const [usersData, setUsersData] = useState([]);
  const [meta, setMeta] = useState<CurrentPageData>();
  const debouncedLocalSearchWord = useDebounce(localSearchWord, 400);
  const token = getToken();

  const { request, loading } = useAxios();
  async function getUserInfo() {
    const res = await request("profile/user", "GET", null, {
      Authorization: `Bearer ${token}`,
    });
    saveToLocalStorageUser("user", res.data);
  }

  useEffect(() => {
    getUserInfo();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [debouncedLocalSearchWord]);
  async function fetchData() {
    const res = await request(
      `admin/user?search=${debouncedLocalSearchWord}`,
      "GET",
      null,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    console.log("res", res);
    setMeta(res?.meta || {});
    setUsersData(res?.data || []);
  }

  return (
    <Wrapper>
      <ContentContainer>
        <Column>
          <TitleTab>{title}</TitleTab>
        </Column>
        <SearchWrap>
          <Search
            searchWord={localSearchWord}
            setSearchWord={setLocalSearchWord}
          />
        </SearchWrap>
        <TableUsers
          fetchData={fetchData}
          actions={actions}
          columns={columns}
          loading={loading}
          data={usersData}
          meta={meta!}
          setMeta={setMeta}
          setUsersData={setUsersData}
        />
        {meta && meta!.total > 15 && (
          <Pagination
            setMeta={setMeta}
            setUsersData={setUsersData}
            links={meta!.links}
            currentPage={meta!.current_page}
            totalPages={meta!.last_page}
          />
        )}
      </ContentContainer>
    </Wrapper>
  );
}
