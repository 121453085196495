import Button from "components/Buttons/Button";
import Input from "components/Inputs/Input";
import styled from "styled-components";
import { baseTheme } from "styles/theme";
import { useAxios } from "hooks/useAxios";
import { getToken } from "helpers";
import { ChangeEvent, useEffect, useMemo, useReducer, useState } from "react";
import SkeletonComponent from "components/Skeletons/Skeleton";
import { TitleSection } from "styles/components";
import EditIcon from "assets/icons/EditIcon";
import InformModal from "./ConfirmModal";
import HospitalsDropdown from "components/HospitalsDropdown";
import { SettingsArrayType } from "interfaces";
import Toast from "components/Toast";

interface IUpdateModalInfo {
  data: SettingsArrayType;
  isHospitals?: boolean;
  type: string;
  label?: string;
  settingsProfile: () => void;
  isLoading: boolean;
  titleList?: string;
}
interface IEDButtons {
  isHospitals?: boolean;
}
const UpdateModalInfoWrapper = styled.div`
  /* padding: 10px; */
  width: 350px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  max-height: 400px;
  @media (min-width: 1921px) {
    width: 450px;
  }
`;
const Form = styled.form`
  width: 100%;
`;
const ButtonWrapper = styled.div`
  padding-top: 5px;
  padding-bottom: 20px;
`;

const CloseIcon = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 14px;
    background-color: #fff;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;

const CloseButton = styled.button`
  background-color: ${baseTheme.colors.error};
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const EditButton = styled.div`
  cursor: pointer;
  padding-top: 5px;
`;
const List = styled.ul`
  width: 350px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  @media (min-width: 1921px) {
    width: 450px;
  }
`;
const ItemList = styled.li`
  padding: 10px 10px 10px 0;
  margin-right: -5px;
  flex-direction: row;
  align-items: center;
  list-style: none;
  display: flex;
  line-height: 20px;
  font-size: 0.9rem;
  flex-direction: row;
  font-family: "Poppins", sans-serif;
  justify-content: space-between;
  border-bottom: 1px solid #d9dddc;
  @media (min-width: 1921px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

const EditInput = styled.input`
  border: none;
  outline: none;
  font-family: "Poppins", sans-serif;
  padding: 4px;
  font-size: 0.9rem;
  border-bottom: 1px solid ${baseTheme.colors.main};
  @media (min-width: 1921px) {
    font-size: 20px;
  }
`;
const SCButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
  @media (min-width: 1921px) {
    width: auto;
  }
`;
const EDButtons = styled.div<IEDButtons>`
  display: flex;
  justify-content: ${(props) =>
    props.isHospitals ? "flex-end" : "space-between"};

  align-items: center;
  width: 70px;
`;

function UpdateModalInfo({
  data,
  type,
  label,
  isHospitals,
  isLoading,
  settingsProfile,
  titleList,
}: IUpdateModalInfo) {
  const token = getToken();
  const [inputValues, setInputValues] = useState({
    newValue: "",
    editValue: "",
  });
  const [showModal, toggleModal] = useReducer((showModal) => !showModal, false);
  const [list, setList] = useState<any>([]);
  const [editingItemId, setEditingItemId] = useState<string | null>(null);
  const [deleteItemId, setDeleteItemId] = useState<string>("");
  const { request, loading, error } = useAxios();

  useEffect(() => {
    // if (type === "set") {
    //   setList(data?.[0]?.["sets"]);
    // }
    if (type === "hospital") {
      setList(data?.[0]?.["hospitals"]);
    }
    if (type === "complication-outcome") {
      setList(data?.[0]?.["complication-outcomes"]);
    }
    if (type === "pathology-outcome") {
      setList(data?.[0]?.["pathology-outcomes"]);
    }
    if (type === "supervisor") {
      setList(data?.[0]?.["supervisors"]);
    }
  }, [type, data]);

  const ButtonDisabled = useMemo(() => {
    return !inputValues.newValue;
  }, [inputValues.newValue]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (type === "set") {
      const numericValue = value.replace(/[^\d.]/g, "");
      setInputValues((prevFormData) => ({
        ...prevFormData,
        [name]: numericValue,
      }));
    } else {
      setInputValues((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    let newData = {};

    if (type === "set") {
      newData = {
        value: inputValues.newValue,
      };
    } else {
      newData = {
        name: inputValues.newValue,
      };
    }
    setInputValues((prevFormData) => ({
      ...prevFormData,
      newValue: "",
    }));
    const res = await request(`profile/${type}`, "POST", newData, {
      Authorization: `Bearer ${token}`,
    });
    if (res) {
      await settingsProfile();
    }
  }

  async function onDelete(id: string) {
    try {
      await request(`profile/${type}/${id}/delete`, "GET", null, {
        Authorization: `Bearer ${token}`,
      });
      toggleModal();
      await settingsProfile();
    } catch (e) {}
  }
  const handleEditClick = (id: string) => {
    setEditingItemId(id);

    const editingItem = list.find((item: any) => item.id === id);
    if (editingItem) {
      setInputValues((prevFormData) => ({
        ...prevFormData,
        editValue: editingItem.value || editingItem.name || "",
      }));
    }
  };

  const handleCancelEdit = () => {
    setEditingItemId(null);
    setInputValues((prevFormData) => ({
      ...prevFormData,
      editValue: "",
    }));
  };

  const handleSaveEdit = async () => {
    let newData = {};

    if (type === "set") {
      newData = {
        value: inputValues.editValue,
      };
    } else {
      newData = {
        name: inputValues.editValue,
      };
    }
    try {
      await request(`profile/${type}/${editingItemId}`, "POST", newData, {
        Authorization: `Bearer ${token}`,
      });
      await settingsProfile();
    } catch (e) {}
    setInputValues((prevFormData) => ({
      ...prevFormData,
      newValue: "",
    }));
    setEditingItemId(null);
  };
  return (
    <>
      <UpdateModalInfoWrapper>
        {isHospitals ? (
          <HospitalsDropdown settingsProfile={settingsProfile} />
        ) : (
          <Form onSubmit={handleSubmit}>
            <Input
              type="text"
              name="newValue"
              required={true}
              placeholder={label}
              value={inputValues.newValue}
              onChange={handleInputChange}
            />
            <ButtonWrapper>
              <Button
                fontWeight={600}
                color={baseTheme.colors.white}
                fontSize="14px"
                disabled={ButtonDisabled || loading || isLoading}
                text="Save"
              />
            </ButtonWrapper>
          </Form>
        )}

        <TitleSection>{titleList}</TitleSection>
        <List>
          {isLoading ? (
            <SkeletonComponent
              width={"100%"}
              height={25}
              style={{ margin: "10px 0" }}
              count={list.length}
            />
          ) : (
            list.map((item: any) => (
              <ItemList key={item.id}>
                {editingItemId === item.id ? (
                  <EditInput
                    type="text"
                    name="editValue"
                    value={inputValues.editValue}
                    onChange={handleInputChange}
                    autoFocus
                  />
                ) : type === "set" ? (
                  item.value
                ) : type === "hospital" ? (
                  item.hospital_list.name
                ) : (
                  item.name
                )}

                {editingItemId === item.id ? (
                  <SCButtons>
                    <Button
                      fontWeight={500}
                      fontSize="0.8rem"
                      text="Save"
                      as="button"
                      bg="transparent"
                      onClick={handleSaveEdit}
                      color={baseTheme.colors.black}
                    />
                    <Button
                      fontWeight={500}
                      fontSize="0.8rem"
                      text="Cancel"
                      as="button"
                      bg="transparent"
                      onClick={handleCancelEdit}
                      color={baseTheme.colors.black}
                    />
                  </SCButtons>
                ) : (
                  <EDButtons isHospitals={isHospitals}>
                    {!isHospitals && (
                      <EditButton
                        onClick={() => handleEditClick(item.id)}
                        role="button"
                      >
                        <EditIcon color={baseTheme.colors.black} />
                      </EditButton>
                    )}
                    <CloseButton
                      onClick={() => {
                        setDeleteItemId(item.id);
                        toggleModal();
                      }}
                    >
                      <CloseIcon />
                    </CloseButton>
                  </EDButtons>
                )}
              </ItemList>
            ))
          )}
        </List>
      </UpdateModalInfoWrapper>
      {error && <Toast error={error} />}
      <InformModal
        isOpen={showModal}
        cancelButton={toggleModal}
        confirmButton={() => onDelete(deleteItemId)}
        title={"Please confirm deleting item from list"}
      />
    </>
  );
}
export default UpdateModalInfo;
