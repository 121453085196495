import { IIcon } from "interfaces";

const EditIcon = ({ color, width = 20, height = 20 }: IIcon) => {
  return (
    <div>
      <svg
        fill="none"
        height={height}
        viewBox="0 0 24 24"
        width={width}
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect fill="white" height={height} width={width} />
        <path
          d="M6.28513 13.091L14.2831 3.60745L15.3286 3.32997L18.1738 5.72949L18.0767 6.80681L10.0788 16.2904L5.89683 17.4003L6.28513 13.091Z"
          stroke="black"
        />
        <path d="M12.7844 5.15353L16.6778 8.61429" stroke="black" />
        <path d="M5.5 19H19.5" stroke="black" strokeLinecap="round" />
      </svg>
    </div>
  );
};

export default EditIcon;
