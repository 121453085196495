import React from "react";
import { BrowserRouter } from "react-router-dom";
import GlobalStyles from "../src/styles/global";
import Routes from "components/Router";
import Meta from "components/Meta";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_API_KEY}`,
  authDomain: `${process.env.REACT_APP_AUTH_DOMAIN}`,
  projectId: `${process.env.REACT_APP_PROJECT_ID}`,
  storageBucket: `${process.env.REACT_APP_STORAGE_BUCKET}`,
  messagingSenderId: `${process.env.REACT_APP_MESSAGIN_SENDER_ID}`,
  appId: `${process.env.REACT_APP_APP_ID}`,
  measurementId: `${process.env.REACT_APP_MEASUREMENT_ID}`,
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
function App() {
  const router = Routes();

  return (
    <>
      <Meta />
      <GlobalStyles />
      <BrowserRouter>
        <div>{router}</div>
      </BrowserRouter>
    </>
  );
}

export default App;
