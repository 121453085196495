import React from "react";

import MainLayout from "layouts/MainLayout";

import Users from "components/Users";



export default function PageUsers() {
  const columns = [
    { Header: "Identifier", accessor: "id" },
    { Header: "First Name", accessor: "first_name" },
    { Header: "Last Name", accessor: "last_name" },
    { Header: "Email Address", accessor: "email" },
    { Header: "Contact Number", accessor: "phone" },
  ];
  return (
    <MainLayout>
      <Users columns={columns} title="Users" />
    </MainLayout>
  );
}
