import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { StyledH3 } from "styles/components";
import ButtonBack from "../Buttons/BackButton";
import "components/TextEditor/style.css";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import Button from "components/Buttons/Button";
import { useAxios } from "hooks/useAxios";
import { baseTheme } from "styles/theme";
import { getToken } from "helpers";
import draftToHtml from "draftjs-to-html";

const Wrapper = styled.div`
  display: flex;
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 800px;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  width: 150px;
  margin: 15px 0;
`;
interface ITextEditor {
  route: string;
  title: string;
}

function TextEditor({ route, title }: ITextEditor) {
  const { request, loading } = useAxios();
  const [privacyPolicy, setPrivacyPolicy] = useState();
  const token = getToken();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    getData();
    if (privacyPolicy) {
      const contentBlocks = convertFromHTML(privacyPolicy);
      const contentState = ContentState.createFromBlockArray(
        contentBlocks.contentBlocks,
        contentBlocks.entityMap
      );
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    }
    // eslint-disable-next-line
  }, [privacyPolicy]);

  async function getData() {
    const type =
      route === "privacy-policy" ? "privacy-policy" : "terms-and-conditions";
    const res = await request(`${type}`, "GET", null);
    setPrivacyPolicy(res.value);
  }

  async function handleSave() {
    const value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    await request(
      `admin/${route}`,
      "POST",
      { value },
      {
        Authorization: `Bearer ${token}`,
      }
    );
    getData();
  }

  const handleEditorStateChange = (newEditorState: any) => {
    setEditorState(newEditorState);
  };

  return (
    <Wrapper>
      <ButtonBack />
      <StyledH3>{title}</StyledH3>
      {editorState && (
        <Editor
          editorState={editorState}
          onEditorStateChange={handleEditorStateChange}
          wrapperClassName="demo-wrapper"
          editorClassName="editor-class"
        />
      )}

      <ButtonWrapper>
        <Button
          onClick={handleSave}
          color={baseTheme.colors.white}
          text="Save"
          loading={loading}
        />
      </ButtonWrapper>
    </Wrapper>
  );
}
export default TextEditor;
